import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import "./StripePayCard.css";

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            backgroundColor: "transparent",
            color: "black",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "rgb(119, 119, 119)"
           },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a"
        }
    }
};

function CardSection() {
    return (
        <label>
            <CardElement options={CARD_ELEMENT_OPTIONS} />
        </label>
    );
}

export default CardSection;
