import React, { useContext } from "react";

import {
    useIonViewDidEnter,
    IonContent,
    IonHeader,
    IonPage,
    IonSlides,
    IonSlide,
    IonLabel,
    IonCard,
    IonTitle,
    IonToolbar,
    IonImg,
    IonIcon,
    IonInput,
    IonButton,
    IonText
} from "@ionic/react";
import { qrCodeSharp, construct } from "ionicons/icons";
import { NONAME } from "dns";
import { Link } from "react-router-dom";
import { SignUpForm } from '../components/SignUpForm';
import { useHistory } from 'react-router-dom';


const router = document.querySelector('ion-router');
const Register: React.FC = () => {
    let history = useHistory();

    return (

        <IonPage>
            <Link to="/products" >
                <IonImg
                    style={{
                        position: "absolute",
                        top: 12,
                        left: 12,
                        width: 44,
                        zIndex: 3
                    }}
                    src="./assets/button_back.png"
                />
            </Link>
            <IonContent
                style={{
                    background: "#F6F7F9"
                }}
            >
                <IonImg
                    style={{
                        width: "50%",
                        marginLeft: "25%",
                        marginTop: 60
                    }}
                    src="./assets/logo_long.png"
                />
                <SignUpForm></SignUpForm>
            </IonContent>
        </IonPage>
    );
};

export default (Register);
