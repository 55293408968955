import React, { useContext } from "react";
import {
    IonContent,
    IonHeader,
    IonPage,
    IonLabel,
    IonCard,
    IonTitle,
    IonToolbar,
    IonImg,
    IonIcon,
    IonInput,
    IonButton
} from "@ionic/react";
import { CreateAnimation } from '@ionic/react';

import { qrCodeSharp } from "ionicons/icons";
import "./Home.css";
import { Menu } from '../components/Menu';
import { ProductCard } from '../components/ProductCard';

import { Link } from "react-router-dom";
import { GlobalContext } from "../actions/globalContext";
const router = document.querySelector('ion-router');
const Products: React.FC = () => {
    const [state, setState] = useContext(GlobalContext);


    return (
        <IonPage>
            {state.menuOpen ?
           
                    <Menu></Menu>

                : ''}
            <IonContent
                class="standard_bg">
                <ProductCard></ProductCard>
            </IonContent>
            {state.cart.length > 0 ? (
                <IonCard
                    style={{
                        position: 'fixed',
                        bottom: 0,
                        margin: 0,
                        backgroundColor: "black",
                        width: '100%',
                        borderRadius: 0,
                        paddingLeft: 15,
                        height: 50
                    }}
                >
                    <IonLabel
                        style={{
                            position: 'relative',
                            top: 15,
                            color: "white"
                        }}
                    >
                        {state.cart.length} Item in cart
                </IonLabel>
                    <Link to="/payment">
                        <IonButton
                            style={{
                                borderRadius: 0,
                                margin: 0,
                                padding: 0,
                                height: '100%',
                                float: 'right',
                                color: 'white',
                                backgroundColor: "#DF1300"
                            }}
                            fill="clear"
                        >
                            Checkout >
                </IonButton>
                    </Link>
                </IonCard>
            ) : ''}
        </IonPage >
    );
};

export default Products;
