import React, { useState } from "react";
const GlobalContext = React.createContext();

const GlobalProvider = (props) => {
    const [state, setState] = useState({
        currentuser: null,
        cart: [],
        currentTab: "",
        showLoginAlert: false,
        showPaymentLoading: false,
        paymentSuccess: false,
        showPaymentAlert: false,
        orderHistory: [],
        loading: false,
        product: null,
        current_machine: null,
        view_product: 0
    });


    return (
        <GlobalContext.Provider value={[state, setState]}>
            {props.children}
        </GlobalContext.Provider>
    );
};

export { GlobalContext, GlobalProvider };
