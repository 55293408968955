import React, { Component, useState, useContext, useEffect } from "react";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonImg,
    IonCardTitle,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonTitle,
    useIonViewWillEnter,
    useIonViewDidEnter
} from "@ionic/react";
import "./ProductCard.css";
import { useHistory, Route } from 'react-router-dom';
import { GlobalContext } from "../actions/globalContext";
import CartActions from "../actions/cartActions";
import { getAllProducts } from "../actions/api/products";


import { Link } from "react-router-dom";
interface Product {
    id: number;
    name: string;
    image_url: string;
    price: number;
}

export const ProductCard: React.FC = () => {

    const [state, setState] = useContext(GlobalContext);
    const [text, setText] = React.useState("Primary Button");
    const [products, setProducts] = useState<Product[]>([]);
    const { removeItem, addToCart } = CartActions();

    const history = useHistory();
   
    useIonViewDidEnter(() => {
        if (state.current_machine == null) {
            history.push("/");
        }
        getProductInfo();
    });


    const getProductInfo = async () => {
        if (state.current_machine != null) {
            const data = await getAllProducts(state.current_machine.machine_id);
            if (data) {
                setProducts(data);
            }
        }

    };

    return (
        <IonCard class="products_wrapper"
            style={{
                paddingBottom: 60
            }}
        >
            <IonCard class="header_img_wrapper">
                <IonImg
                    onClick={(e) => {
                        let { currentuser, menuOpen } = state;
                        menuOpen = true;
                        setState({ ...state, menuOpen });

                    }}

                    src="./assets/button_menu.svg"
                    style={{
                        position: "absolute",
                        left: 5,
                        top: 16,
                        minWidth: "7%"
                    }}
                />
                <IonImg
                    class="header_img"
                    src="./assets/logo_long.png"
                ></IonImg>
            </IonCard>
            {products!.map(product => {
                return (
                    <IonCard className="container" key={product.id} >
                        <IonCard
                            style={{
                                background: "transparent",
                                margin: 0,
                                "--background": "none",
                                padding: 0,
                                boxShadow: "none"
                            }}
                        >

                            <img className="productImg"
                                onClick={(e) => {
                                    setState({ ...state, product: product });
                                    history.push("/productdetail/" + product.id);
                                }}
                                src={product.image_url}></img>


                        </IonCard>
                        <div className="priceName">
                            <IonCardTitle
                                style={{
                                    textAlign: "left",
                                    color: "black",
                                    fontWeight: "bold",
                                }}
                                className="priceSize">
                                ${product.price}.00
              </IonCardTitle>

                            <h4 className="productName">{product.name}</h4>
                        </div>
                        {state.cart.find((e: any) => e.id === product.id) ? (
                            <IonButton
                                class="product_button added"
                                size="default"
                                expand="block"
                                style={{
                                    backgroundColor: "black",
                                    padding: 0,
                                    borderRadius: 8,
                                    color: 'white',
                                    marginTop: 10,
                                    height: 30,
                                    fontWeight: 300,
                                    fontSize: 14
                                }}
                                fill="clear"
                                onClick={e => {
                                    console.log(product);
                                    removeItem(product);
                                }}
                            >
                                ITEM ADDED
              </IonButton>
                        ) : (
                                <IonButton
                                    size="default"
                                    expand="block"
                                    class="product_button"
                                    style={{
                                        backgroundColor: "transparent",
                                        padding: 0,
                                        borderRadius: 8,
                                        color: 'black',
                                        marginTop: 10,
                                        border: '1px solid #DDDDDD',
                                        height: 30,
                                        fontWeight: 300,
                                        fontSize: 14
                                    }}
                                    fill="clear"
                                    onClick={e => {
                                        console.log(product);
                                        addToCart(product);
                                    }}
                                >
                                    + ADD
              </IonButton>
                            )}
                    </IonCard>
                );
            })}

        </IonCard>
    );
};

export default ProductCard;
