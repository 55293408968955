import React, { useContext } from "react";

import {
    useIonViewDidEnter,
    IonContent,
    IonHeader,
    IonPage,
    IonSlides,
    IonSlide,
    IonLabel,
    IonCard,
    IonTitle,
    IonToolbar,
    IonImg,
    IonIcon,
    IonInput,
    IonButton,
    IonText
} from "@ionic/react";
import { qrCodeSharp, construct } from "ionicons/icons";
import { NONAME } from "dns";
import { useHistory } from 'react-router-dom';


const router = document.querySelector('ion-router');
const Thankyou: React.FC = () => {
    let history = useHistory();
    useIonViewDidEnter(() => {
        setTimeout(() => {
            history.push("/");
            window.location.href = "/";
        }, 5000);
    });
    return (

        <IonPage>
            <IonContent
                style={{
                    background: "#F6F7F9"
                }}
            >
                <IonImg
                    style={{
                        width: 110,
                        margin: "16px 0px",
                        position: 'relative',
                        left: 0,
                        right: 0,
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }}
                    src="./assets/logo_Nanobar_store_horizontal.png"
                />
                <IonImg
                    style={{
                        marginTop: "20%",
                        width: "35%",
                        position: 'relative',
                        left: 0,
                        right: 0,
                        marginLeft: 'auto',
                        marginRight: 'auto'

                    }}
                    src="./assets/checkout_animation(apng).png"
                />
                <IonLabel
                    style={{
                        color: "black",
                        fontWeight: "bold",
                        marginTop: 23,
                        fontize: 16,
                        textAlign: "center",
                        width: "100%",
                        display: "block"
                    }}
                >
                    Thank you for your purchase
                </IonLabel>
                <IonLabel
                    style={{
                        color: "black",
                        fontize: 14,
                        textAlign: "center",
                        width: "100%",
                        display: "block"
                    }}
                >
                    Door compartment will open moentarily
                </IonLabel>
            </IonContent>
        </IonPage>
    );
};

export default (Thankyou);
