import React, { useContext } from "react";

import {
    IonContent,
    IonHeader,
    IonPage,
    IonCard,
    IonTitle,
    IonToolbar,
    IonImg,
    IonIcon,
    IonInput,
    IonButton
} from "@ionic/react";
import { Link } from "react-router-dom";
import { qrCodeSharp } from "ionicons/icons";
import "./Home.css";
import Card from "../components/Card";
import { getMachine } from "../actions/api/products";
import Howitworks from "../components/HowitworksCard";
import { isEmpty } from "../util";
import { useHistory } from 'react-router-dom';

import { GlobalContext } from "../actions/globalContext";
const router = document.querySelector('ion-router');
const Home: React.FC = () => {
    const [state, setState] = useContext(GlobalContext);
    const history = useHistory();
    var code:any;
    const submit = async () => {
        const data = await getMachine({
            code
        });
        if (data) {
            if (!isEmpty(data.results)) {
                setState({ ...state, current_machine: data.results });

                history.push("/products");
            } else {
                alert("Invalid machine code");
            }

        }
    };
    return (
        <IonPage>
            <IonContent
                class="cover_page">
                <IonImg
                    class="cover_img"
                    src="./assets/store_landing_bg.png" />
                <IonCard
                    class="button_wrapper"
                >
                    <IonInput
                        class="cover_input"
                        placeholder="Enter box no."
                        onIonChange={e => {
                            code = e.detail.value;
                        }}
                    ></IonInput>
                    <IonButton
                        onClick={submit}
                        style={{
                            width: '100%',
                            height: 40,
                            borderRadius: 22,
                            marginTop: 14,
                            background: "black",
                            color: "white"
                        }}
                        fill="clear"
                    >
                        Go
                    </IonButton>
                </IonCard>

            </IonContent>

        </IonPage >
    );
};

export default Home;