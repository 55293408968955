import React, { useContext } from "react";
import {
    IonContent,
    IonHeader,
    IonPage,
    IonLabel,
    IonCard,
    IonTitle,
    IonToolbar,
    IonImg,
    IonIcon,
    IonInput,
    IonButton
} from "@ionic/react";
import { CreateAnimation } from '@ionic/react';

import { qrCodeSharp } from "ionicons/icons";
import "./Home.css";
import { Menu } from '../components/Menu';
import { ProductCard } from '../components/ProductCard';

import { Link } from "react-router-dom";
import { GlobalContext } from "../actions/globalContext";

const router = document.querySelector('ion-router');
const Howitworks: React.FC = () => {
    const [state, setState] = useContext(GlobalContext);


    return (
        <IonPage>
            <IonContent>
                <Link
                    to="/products">
                    <IonImg
                        style={{
                            marginTop: 12,
                            marginLeft: 17,
                            width: 44
                        }}
                        src="./assets/button_back.png"
                    />
                </Link>
                <IonLabel
                    style={{
                        marginLeft: 23,
                        marginTop: 24,
                        fontSize: 16,
                        display: "block",
                        color: "black",
                        fontWeight: "bold"
                    }}
                >
                    How it works
            </IonLabel>
                <IonLabel
                    style={{
                        marginTop: 28,
                        marginLeft: 23,
                        fontSize: 16,
                        display: "block",
                        color: "black",
                        paddingRight: "20%"
                    }}
                >
                    Select the items to you’d like to purchase and checkout. Once that checkout is processed, the designated compartment door of the machine would open shortly.
            </IonLabel>
            </IonContent>
        </IonPage>
    );
};

export default Howitworks;
