import React, { useContext } from "react";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { Redirect, Route } from "react-router-dom";
import {
    IonApp,
    IonIcon,
    IonLabel,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonPage
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { personOutline, cartOutline, homeOutline } from "ionicons/icons";
import Home from "./Home";
import Checkout from "./Checkout";
import Account from "./Account";
import Products from "./Product";
import Camera from "./Camera";
import Cart from "./Cart";
import Payment from "./Payment";
import Thankyou from "./Thankyou";
import ProductDetail from "./ProductDetail";
import Howitworks from "./Howitworks";
import OrderHistory from "./OrderHistory";
import Login from "./Login";
import Register from "./Register";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "../theme/variables.css";

/* Context */
import { GlobalContext } from "../actions/globalContext";

import GlobalActions from "../actions/globalActions";

import { getUsers, login, guestlogin } from "../actions/api/accounts";
import { isEmpty } from "../util";

const Index: React.FC = () => {
    defineCustomElements(window);
    const [state, setState] = useContext(GlobalContext);
    const { changeTabs } = GlobalActions();
    console.log("App", state);
    
    const checkLogin = async ()=>{
        if(state.currentuser == null){
            const data = await guestlogin({});

            // const data = await login({ username: "Guest Account", password: '1234' });
            if (data && !isEmpty(data.results)) {
                setState({ ...state, currentuser: data.results });
            }else{
                console.log(data);
            }
        }else{
            console.log(state.currentuser);
        }
    }
    checkLogin();
 

    return (
        <IonRouterOutlet>
            <Route path="/home" component={Home} exact={true} />
            <Route path="/products" component={Products} exact={true} />
            <Route path="/checkout" component={Checkout} exact={true} />
            <Route path="/account" component={Account} />
            <Route path="/camera" component={Camera} />
            <Route path="/cart" component={Cart} />
            <Route path="/thankyou" component={Thankyou} />
            <Route path="/howitworks" component={Howitworks} />
            <Route path="/payment" component={Payment} />
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
            <Route path="/orderhistory" component={OrderHistory} />
            <Route path="/productdetail/:id" component={ProductDetail}></Route>
            <Route path="/" render={() => <Redirect to="/home" />} exact={true} />

        </IonRouterOutlet>

    );
};

export default Index;
