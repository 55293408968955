import { useContext } from "react";
import { GlobalContext } from "./globalContext";

const CartActions = () => {
    const [state, setState] = useContext(GlobalContext);

    function addToCart(data) {
        let { currentuser, cart } = state;
        cart.push(data);
        setState({ ...state, cart });
        // removed for guest checkout
        // if (currentuser) {
        //     cart.push(data);
        //     setState({ ...state, cart });
        // } else {
        //     console.log("Login Required");
        //     setState({ ...state, showLoginAlert: true });
        // }
    }

    function removeItem(data) {
        let { cart } = state;
        let toRemove = data;
        let filteredArray = cart.filter(function (item) {
            console.log('remove', item);
            return item.id !== toRemove.id;
        });

        setState({ ...state, cart: filteredArray });
    }

    function checkOut() {
        let { cart, currentuser } = state;
        const obj = {
            cart: cart.map((x) => x.machine_product_id),
            position: cart.map((x) => x.position),
            totalAmount: cart.reduce((a, b) => a + b.price, 0),
            userid: currentuser == null ? '':currentuser.id
        };
        console.log(cart);
        return obj;
    }

    function clearCart() {
        setState({ ...state, cart: [] });
    }

    return {
        addToCart,
        removeItem,
        clearCart,
        checkOut,
    };
};

export default CartActions;
