import React, { useState, useContext } from "react";
import { GlobalContext } from "../actions/globalContext";
import "./SignUpForm.css";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonInput
} from "@ionic/react";
import { getUsers, createUser } from "../actions/api/accounts";
import { isEmpty } from "../util";
import { useHistory } from 'react-router-dom';

export const SignUpForm: React.FC = () => {
    const history = useHistory();
    const [state, setState] = useContext(GlobalContext);
    const [form, setForm] = useState({
        username: "",
        password: "",
        firstname: "",
        lastname: "",
        email: ""
    });

    const onInputChange = (obj: any) => {
        setForm({ ...form, [obj.key]: obj.value });
    };

    const submit = async () => {
        const data = await createUser({
            username,
            password,
            firstname,
            lastname,
            email
        });
        if (data) {
            if (!isEmpty(data.results)) {
                setState({ ...state, currentuser: data.results });

                history.push("/products");
            } else {
                alert(data.msg);
            }

        }
    };

    const { username, email, password, lastname, firstname } = form;

    return (
        <IonCard className="card login_form">
            <IonCardContent>
                <IonLabel position="floating">
                    Username{<span style={{ color: "red" }}> * </span>}
                </IonLabel>
                <IonInput
                    style={{
                        backgroundColor: "#DDDDDD",
                        border: "1px solid #DDDDDD",
                        paddingLeft: 20,
                        marginBottom: 15
                    }}
                    value={username}
                    onIonChange={e => {
                        onInputChange({ key: "username", value: e.detail.value });
                    }}
                    required
                ></IonInput>
                <IonLabel position="floating">
                    Password{<span style={{ color: "red" }}> * </span>}
                </IonLabel>
                <IonInput
                    style={{
                        backgroundColor: "#DDDDDD",
                        border: "1px solid #DDDDDD",
                        paddingLeft: 20,
                        marginBottom: 15
                    }}
                    value={password}
                    onIonChange={e => {
                        onInputChange({ key: "password", value: e.detail.value });
                    }}
                    required
                ></IonInput>
                <IonLabel position="floating">
                    First Name{<span style={{ color: "red" }}> * </span>}
                </IonLabel>
                <IonInput
                    style={{
                        backgroundColor: "#DDDDDD",
                        border: "1px solid #DDDDDD",
                        paddingLeft: 20,
                        marginBottom: 15
                    }}
                    value={firstname}
                    onIonChange={e => {
                        onInputChange({ key: "firstname", value: e.detail.value });
                    }}
                    required
                ></IonInput>
                <IonLabel position="floating">
                    Last Name{<span style={{ color: "red" }}> * </span>}
                </IonLabel>
                <IonInput
                    style={{
                        backgroundColor: "#DDDDDD",
                        border: "1px solid #DDDDDD",
                        paddingLeft: 20,
                        marginBottom: 15
                    }}
                    value={lastname}
                    onIonChange={e => {
                        onInputChange({ key: "lastname", value: e.detail.value });
                    }}
                    required
                ></IonInput>
                <IonLabel position="floating">
                    Email{<span style={{ color: "red" }}> * </span>}
                </IonLabel>
                <IonInput
                    style={{
                        backgroundColor: "#DDDDDD",
                        border: "1px solid #DDDDDD",
                        paddingLeft: 20,
                        marginBottom: 15
                    }}
                    value={email}
                    onIonChange={e => {
                        onInputChange({ key: "email", value: e.detail.value });
                    }}
                    required
                ></IonInput>
                <section>
                    <IonButton
                        style={{
                            borderRadius: 4,
                            marginBottom: 14,
                            height: 40,
                            paddingLeft: 14,
                            paddingRight: 14,
                            color: "white",
                            backgroundColor: "black"
                        }}
                        fill="clear"
                        className="signUpButton" expand="block" onClick={submit}>
                        Sign Up
          </IonButton>
                </section>
            </IonCardContent>
        </IonCard>
    );
};

export default SignUpForm;
