import React, { useContext } from "react";

import {
    IonContent,
    IonHeader,
    IonPage,
    IonSlides,
    IonSlide,
    IonLabel,
    IonCard,
    IonTitle,
    IonToolbar,
    IonImg,
    IonIcon,
    IonInput,
    IonButton,
    IonText
} from "@ionic/react";
import { qrCodeSharp } from "ionicons/icons";

import { GlobalContext } from "../actions/globalContext";
import { NONAME } from "dns";

// Stripe Stuff
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeCheckoutForm from "../components/StripeCheckoutForm";
import CartActions from '../actions/cartActions';
import Cart from "./Cart";
import { Link } from "react-router-dom";

const router = document.querySelector('ion-router');
const Payment: React.FC = () => {
    const [state, setState] = useContext(GlobalContext);


    const stripePromise = loadStripe("pk_test_nLp0TNh4V272938r4hYeX36U00Li5S8Vpr");

    const { cart, showPaymentLoading, showPaymentAlert, paymentSuccess } = state;
    console.log(state.cart);
    const { checkOut } = CartActions();
    const totalAmount = checkOut().totalAmount;
    var showCart = false;
    return (
        <IonPage>
            <IonContent
                style={{
                    background: "#F6F7F9"
                }}
            >
                <IonCard
                    style={{
                        margin: 16,
                        background: 'transparent',
                        boxShadow: "none",
                        display: "flex"
                    }}
                >
                    <IonCard

                        style={{
                            margin: 0,
                            background: 'transparent',
                            boxShadow: "none",
                            minWidth: '50%',
                            maxWidth: '50%'
                        }}
                    >
                        <Link to="/products" style={{
                            color: "black",
                            textDecoration: "none"
                        }}>
                            <IonImg
                                src="./assets/button_back.png"
                                style={{ width: 44 }}
                            />
                        </Link>
                    </IonCard>
                    <IonCard
                        style={{
                            margin: 0,
                            background: 'transparent',
                            boxShadow: "none",
                            minWidth: '50%',
                            maxWidth: '50%'
                        }}
                    ><IonImg src="./assets/logo_long.png" /></IonCard>
                </IonCard>
                <IonCard
                    style={{
                        background: "white",
                        boxShadow: "0px 2px 2px #00000033",
                        borderRadius: 8,
                        marginBottom: 0
                    }}
                >
                    <IonCard
                        style={{
                            display: "flex",
                            background: "transparent",
                            margin: 0,
                            boxShadow: "none",
                            borderRadius: 0,
                            borderBottom: "1px solid #777777"
                        }}
                    >
                        <IonLabel style={{
                            minWidth: '50%',
                            padding: "6px 10px",
                            color: "#777777"
                        }}>Items ({state.cart.length})</IonLabel>
                        <IonLabel
                            onClick={(e) => {

                                if (!state.showCart) {
                                    setState({ ...state, showCart: true });
                                } else {
                                    setState({ ...state, showCart: false });
                                }
                            }}
                            style={{
                                minWidth: '50%',
                                textAlign: "right",
                                padding: "6px 10px",
                                color: "#777777"
                            }}
                        >
                            {state.showCart ? 'hide' : 'show'}
                        </IonLabel>
                    </IonCard>
                    {state.showCart && cart && cart.length > 0 ? (
                        cart.map(function (x: any) {
                            return (
                                <IonCard
                                    style={{
                                        display: "flex",
                                        background: "#EAEAEA",
                                        margin: 0,
                                        boxShadow: "none",
                                        borderRadius: 0
                                    }}>
                                    <IonLabel
                                        style={{
                                            minWidth: '50%',
                                            padding: "15px 10px",
                                            color: "black",
                                            fontSize: 14,
                                        }}>
                                        {x.name}
                                    </IonLabel>
                                    <IonLabel
                                        style={{
                                            minWidth: '50%',
                                            textAlign: "right",
                                            color: "black",
                                            padding: "15px 10px",
                                            fontSize: 14,
                                        }}>
                                        USD ${x.price}
                                    </IonLabel>
                                </IonCard>
                            )
                        })
                    ) : ''}
                    <IonCard
                        style={{
                            display: "flex",
                            background: "transparent",
                            margin: 0,
                            boxShadow: "none",
                            borderRadius: 0
                        }}>
                        <IonLabel
                            style={{
                                minWidth: '50%',
                                padding: "15px 10px",
                                color: "black",
                                fontSize: 14,
                            }}>
                            Total
                        </IonLabel>
                        <IonLabel
                            style={{
                                minWidth: '50%',
                                textAlign: "right",
                                color: "black",
                                padding: "15px 10px",
                                fontSize: 14,
                            }}>
                            USD ${totalAmount}
                        </IonLabel>
                    </IonCard>
                </IonCard>
                <IonLabel
                    style={{
                        padding: 16,
                        textAlign: "center",
                        fontSize: 12,
                        color: "#777777",
                        margin: 0,
                        width: "100%",
                        display: "block"
                    }}
                >
                    Door compartment will open after purchase.
                </IonLabel>
                <IonCard
                    style={{
                        background: "#777777",
                        height: 1,
                        margin: 0,
                        width: "100%",
                        padding: 0,
                        border: "none",
                        boxShadow: "none"
                    }}
                ></IonCard>
                <IonLabel
                    style={{
                        padding: 16,
                        textAlign: "center",
                        fontSize: 12,
                        color: "#777777",
                        margin: 0,
                        width: "100%",
                        display: "block"
                    }}
                >
                    Do you have an account ?
                </IonLabel>

                <Link to="/login"
                    style={{
                        textDecoration: "none",
                        display: "block",
                    }}
                >
                    <IonLabel
                        style={{
                            width: '80%',
                            height: 40,
                            fontSize:14,
                            marginLeft: "10%",
                            borderRadius: 22,
                            marginTop: 14,
                            display: "block",
                            textAlign: "center",
                            padding: 10,
                            background: "black",
                            color: "white"
                        }}
                    >
                        Yes, Login Now
                    </IonLabel>
                </Link>
                <IonLabel
                    style={{
                        padding: 16,
                        textAlign: "center",
                        fontSize: 12,
                        color: "#777777",
                        margin: 0,
                        width: "100%",
                        display: "block",
                        marginBottom: 0,
                        paddingBottom: 0,
                        position: "relative",
                        top: 20
                    }}
                >
                    No, continue as guest
                </IonLabel>

                <Elements stripe={stripePromise}>
                    <StripeCheckoutForm />
                </Elements>
                {/* <IonLabel
                    style={{
                        padding: 0,
                        textAlign: "center",
                        fontSize: 14,
                        color: "black",
                        margin: "25px 0 16px 0",
                        width: "100%",
                        display: "block"
                    }}>
                    Pay with card
                </IonLabel>


                <IonCard
                    style={{
                        background: "white",
                        borderRadius: 4,
                        boxShadow: "none",
                        border: "1px solid #DDDDDD",
                        margin: "0 13px"
                    }}
                >
                    <IonCard
                        style={{
                            background: "transparent",
                            borderRadius: 0,
                            boxShadow: "none",
                            display: "flex",
                            borderBottom: "1px solid #DDDDDD",
                            margin: 0
                        }}
                    >
                        <IonLabel
                            style={{
                                minWidth: "30%",
                                maxWidth: "30%",
                                fontSize: 19,
                                padding: "11px 12px",
                                color: "black"
                            }}
                        >Name</IonLabel>
                        <IonInput
                            placeholder="Name on card"
                            style={{
                                minWidth: "70%",
                                maxWidth: "70%",
                                fontSize: 19,
                                padding: "11px 12px"
                            }}
                        >
                        </IonInput>
                    </IonCard>
                    <IonCard
                        style={{
                            background: "transparent",
                            borderRadius: 0,
                            boxShadow: "none",
                            display: "flex",
                            margin: 0
                        }}
                    >
                        <IonLabel
                            style={{
                                minWidth: "30%",
                                maxWidth: "30%",
                                fontSize: 19,
                                padding: "11px 12px",
                                color: "black"
                            }}
                        >Email</IonLabel>
                        <IonInput
                            placeholder="send@receipt.com"
                            style={{
                                minWidth: "70%",
                                maxWidth: "70%",
                                fontSize: 19,
                                padding: "11px 12px"
                            }}
                        >


                        </IonInput>
                    </IonCard>


                </IonCard>
                <IonCard
                    style={{
                        display: "flex",
                        margin: "20px 13px",
                        background: "white",
                        boxShadow: "none",
                        border: "1px solid #DDDDDD",
                        padding: "0px 11px",
                        borderRadius: 4
                    }}
                >
                    <IonInput
                        style={{
                            minWidth: '60%',
                            padding: 0,
                            maxWidth: '60%'
                        }}
                        placeholder="Card number"
                    ></IonInput>
                    <IonInput
                        style={{
                            minWidth: '20%',
                            padding: 0,
                            maxWidth: '20%'
                        }}
                        placeholder="MM / YY"
                    ></IonInput>
                    <IonInput
                        style={{
                            minWidth: '20%',
                            padding: 0,
                            maxWidth: '20%'
                        }}
                        placeholder="CVC"
                    ></IonInput>
                </IonCard>

                <IonButton
                    href="/thankyou"
                    fill="clear"
                    style={{
                        margin: "0 13px",
                        borderRadius: 22,
                        background: "black",
                        color: "white",
                        fontWeight: "bold",
                        padding: 9,
                        textAlign: "center",
                        width: "calc(100% - 26px)"
                    }}
                >
                    Pay $15.90
                </IonButton> */}
            </IonContent>
        </IonPage>
    );
};

export default Payment;
