import { fetcher } from "./index";

// Testing
export const getAllProducts = (machine_id) => {
  return fetcher() 
    .post(`/api/listproducts`, { machine_id: machine_id})
    .then(response => {
      return response.data;
    })
    .catch(err => console.log("Error", err));
};

export const getOrdersByUserId = data => {
  return fetcher()
    .post(`/api/getorders`, data)
    .then(response => {
      return response.data;
    })
    .catch(err => console.log("Error", err));
};

export const getMachine = (code) => {
    return fetcher()
        .post("/api/getmachine", code)
        .then(response => {
            return response.data;
        })
        .catch(err => console.log("ERROR", err));
}
