import React, { useContext, useState, useEffect } from "react";
import {
    IonContent,
    IonHeader,
    IonPage,
    IonLabel,
    IonCard,
    IonTitle,
    IonToolbar,
    IonImg,
    IonIcon,
    IonInput,
    IonButton,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCardSubtitle,
    IonCol
} from "@ionic/react";
import { CreateAnimation } from '@ionic/react';

import { qrCodeSharp } from "ionicons/icons";
import "./Home.css";
import { Menu } from '../components/Menu';
import { ProductCard } from '../components/ProductCard';

import { Link } from "react-router-dom";
import { GlobalContext } from "../actions/globalContext";
import { getOrdersByUserId } from "../actions/api/products";

const moment = require("moment");

const router = document.querySelector('ion-router');
const OrderHistory: React.FC = () => {
    const [state, setState] = useContext(GlobalContext);

    const [selectedSegment, setSegment] = useState("signup");

    const { currentuser, orderHistory } = state;

    const getOrders = async () => {
        let { id } = currentuser;
        const data = await getOrdersByUserId({ id });
        if (data) {
            setState({ ...state, orderHistory: data.results });
        }
    };
    useEffect(() => {
        if (currentuser) getOrders();
    }, [currentuser]);


    return (
        <IonPage>
            <IonContent
                class="standard_bg">
                <Link
                    to="/products">
                    <IonImg
                        style={{
                            marginTop: 12,
                            marginLeft: 17,
                            width: 44
                        }}
                        src="./assets/button_back.png"
                    />
                </Link>
                <IonLabel
                    style={{
                        display: "block",
                        marginTop: 20,
                        marginLeft: 40,
                        fontSize: 16,
                        fontWeight: "bold"
                    }}
                >
                    Order History
                </IonLabel>
                {orderHistory.map((order: any) => {
                    let { order_id, created_on, total_amount } = order[0];
                    return (
                        <IonCard className="card" key={`order-${order_id}`}>
                            <IonCardContent>
                                <IonGrid>
                                    <IonRow>
                                        <IonCol>
                                            <IonCardSubtitle>{`Order id: ${order_id}`}</IonCardSubtitle>
                                        </IonCol>
                                        <IonCol>
                                            <IonCardSubtitle>{`${moment(created_on).format(
                                                "DD/MM/YYYY"
                                            )}`}</IonCardSubtitle>
                                        </IonCol>
                                        <IonCol>
                                            <IonCardSubtitle>{`Total $${total_amount}`}</IonCardSubtitle>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                                {order.map((product: any) => (
                                    <IonRow>
                                        <IonCol>
                                            <img
                                                className="cartImg"
                                                src={product.image_url}
                                                alt="Avatar"
                                            />
                                        </IonCol>
                                        <IonCol className="textColumn">
                                            <IonCardSubtitle>
                                                {product.product_name}
                                            </IonCardSubtitle>
                                            <IonCardSubtitle>
                                                ${product.price}}
                                            </IonCardSubtitle>
                                        </IonCol>
                                    </IonRow>
                                ))}
                            </IonCardContent>
                        </IonCard>
                    );
                })}
            </IonContent>

        </IonPage >
    );
};

export default OrderHistory;
