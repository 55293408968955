import React, { useContext }  from "react";

import {
    IonContent,
    IonHeader,
    IonPage,
    IonSlides,
    IonSlide,
    IonLabel,
    IonCard,
    IonTitle,
    IonToolbar,
    IonImg,
    useIonViewWillEnter,
    
    useIonViewDidEnter,
    IonIcon,
    IonInput,
    IonButton
} from "@ionic/react";
import { Link } from "react-router-dom";
import { qrCodeSharp } from "ionicons/icons";
import "./Home.css";
import { getAllProducts } from "../actions/api/products";
import { GlobalContext } from "../actions/globalContext";
import { RouteComponentProps } from "react-router";
import CartActions from "../actions/cartActions";
const router = document.querySelector('ion-router');

interface ProductDetailPageProps extends RouteComponentProps<{ id: string }> { }

const ProductDetail: React.FC<ProductDetailPageProps> = ({ match }) => {

    const [state, setState] = useContext(GlobalContext);

    const { removeItem, addToCart } = CartActions();
    let product = {
        deleted: 0,
        discount_price: 0,
        has_discount: 0,
        id: 1,
        image_url: "",
        name: "",
        price: 0
    };



    // var getProductInfo = async () => {
    //     console.log(match.params.id);
    //     console.log(state.product_id);
    //     const data = await getAllProducts();
    //     if (data) {
    //         for (let i = 0; i < data.length; i++) {
    //             if (data[i].id == match.params.id) {
    //                 product = data[i];
    //                 console.log('selected product', product);
    //                 setState({ ...state, product: product });
    //             }
    //         }
    //     }
    // };


    useIonViewDidEnter(() => {
       // alert(match.params.id);
        console.log('test_products', state.product );
        // getAllProducts().then(data => {
        //     if (data) {
        //         for (let i = 0; i < data.length; i++) {
        //             if (data[i].id == match.params.id) {
        //                 product = data[i];
        //                 console.log('selected product', product);
        //                 setState({ ...state, product: product });
        //             }
        //         }
        //     }
        // });

    });


    if (state.product) {
        return (

            <IonPage
            >
                <IonContent
                    class="standard_bg">

                    <IonCard
                        style={{
                            margin: 0,
                            position: 'relative',
                            boxShadow: "none",
                            background: "white",
                            borderRadius: 0
                        }}
                    >
                        <Link to="/products"

                        >
                            <IonImg
                                style={{
                                    position: "absolute",
                                    top: 12,
                                    left: 12,
                                    width: 44,
                                    zIndex: 3
                                }}
                                src="./assets/button_back.png"
                            />

                        </Link>
                        <IonSlides>
                            <IonSlide>
                                <IonImg
                                    src={state.product.image_url}
                                />
                            </IonSlide>
                        </IonSlides>
                    </IonCard>
                    <IonCard
                        style={{
                            background: "transparent",
                            boxShadow: "none",
                            marginTop: 16,
                            padding: 0
                        }}
                    >
                        <IonLabel
                            style={{

                                fontSize: 14,
                                color: "black"
                            }}
                        >
                            {state.product.name}
                        </IonLabel>
                        <IonLabel
                            style={{
                                float: 'right'
                            }}>
                            <IonLabel
                                style={{
                                    // textDecoration: "line-through",
                                    display: "block",
                                    fontSize: 16,
                                    fontWeight: "bold"
                                }}
                            >${state.product.price}</IonLabel>
                            {/* <IonLabel
                                style={{
                                    display: "block",
                                    fontSize: 16,
                                    fontWeight: "bold",
                                    color: "#DF1300"
                                }}
                            >$15.90</IonLabel> */}
                        </IonLabel>
                    </IonCard>
                    <IonCard
                        style={{
                            background: "transparent",
                            boxShadow: "none"
                        }}
                    >
                        <IonLabel
                            style={{
                                color: "black",
                                fontSize: 14
                            }}
                        >
                            { state.product.description }
                    </IonLabel>
                    </IonCard>

                </IonContent>
                <IonCard
                    style={{
                        background: "black",
                        margin: 0,
                        position: 'fixed',
                        width: '100%',
                        height: 50,
                        display: "flex",
                        bottom: 0,
                        borderRadius: 0
                    }}
                >
                    <IonLabel
                        style={{
                            minWidth: "50%",
                            maxWidth: "50%",
                            textAlign: "center",
                            fontSize: 12,
                            color: "white",
                            paddingTop: 16
                        }}
                        onClick={(e) => {
                            if (state.cart.find((e: any) => e.id === state.product.id)) {
                                removeItem(state.product);
                            } else {
                                addToCart(state.product);
                            }

                        }}
                    >
                        {state.cart.find((e: any) => e.id === state.product.id) ? (
                            "Remove From Cart") : "Add To Cart"}
                    </IonLabel>
                    <Link to="/payment"
                        style={{
                            minWidth: "50%",
                            maxWidth: "50%",
                            textAlign: "center",
                            background: "#DF1300",
                            color: "white",
                            fontSize: 12,
                            fontWeight: "bold",
                            textDecoration: "none",
                            paddingTop: 16
                        }}
                    >
                        <IonLabel
                            style={{ color: 'white'}}
                        >
                            Buy Now
                    </IonLabel>
                    </Link>
                </IonCard>
            </IonPage >

        );
    } else {
        return <IonPage></IonPage>;
    }

};

export default ProductDetail;
