import React, { Component, useState, useContext, useEffect } from "react";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonImg,
    IonCardTitle,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonTitle,
    useIonViewWillEnter,
    useIonViewDidEnter
} from "@ionic/react";


import { Link } from "react-router-dom";
import { GlobalContext } from "../actions/globalContext";
export const Menu: React.FC = () => {

    const [state, setState] = useContext(GlobalContext);
    const link_styles = {
        fontSize: 16,
        display: "block",
        color: "black",
        marginTop: 28,
        fontWeight: "bold",
    };
    if (state.currentuser.email == 'guest_mail')
        return (
            <IonCard
                style={{
                    position: "fixed",
                    height: "100%",
                    width: "100%",
                    background: "#00000080",
                    left: 0,
                    top: 0,
                    margin: 0,
                    borderRadius: 0,
                    zIndex: 4
                }}
            >
                {
                    state.menuOpen == true ? (
                        <IonCard
                            class="testMenu"
                            style={{
                                height: "100%",
                                width: "45%",
                                background: "white",
                                left: 0,
                                paddingLeft: 15,
                                margin: 0,
                                top: 0,
                                borderRadius: 0,
                                zIndex: 4
                            }}
                        >
                            <IonImg
                                src="./assets/button_close.png"
                                onClick={(e) => {
                                    let { currentuser, menuOpen } = state;
                                    menuOpen = false;
                                    setState({ ...state, menuOpen });
                                }}
                                style={{
                                    marginTop: 25,
                                    width: "10%"
                                }}
                            />

                            <IonLabel
                                style={{
                                    marginTop: 30,
                                    color: "#9A9A9A",
                                    fontSize: 12,
                                    display: "block"
                                }}
                            >Guest ID</IonLabel>
                            <IonLabel
                                style={{
                                    color: "#9A9A9A",
                                    fontSize: 16,
                                    display: "block"
                                }}
                            >{state.currentuser.username}</IonLabel>
                            <Link
                                style={{
                                    textDecoration: "none"
                                }}
                                to="/register"
                            >
                                <IonLabel
                                    style={link_styles}
                                >Register</IonLabel>
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none"
                                }}
                                to="/login"
                            >
                                <IonLabel
                                    style={link_styles}
                                >Login</IonLabel>
                            </Link>

                            <hr
                                style={{
                                    background: "#DDDDDD",
                                    marginLeft: -16,
                                    marginTop: 28
                                }}
                            />
                        </IonCard>
                    ) : ''
                }
            </IonCard>
        )
    else
        return (
            <IonCard
                style={{
                    position: "fixed",
                    height: "100%",
                    width: "100%",
                    background: "#00000080",
                    left: 0,
                    top: 0,
                    margin: 0,
                    borderRadius: 0,
                    zIndex: 4
                }}
            >
                {
                    state.menuOpen == true ? (
                        <IonCard
                            class="testMenu"
                            style={{
                                height: "100%",
                                width: "45%",
                                background: "white",
                                left: 0,
                                paddingLeft: 15,
                                margin: 0,
                                top: 0,
                                borderRadius: 0,
                                zIndex: 4
                            }}
                        >
                            <IonImg
                                src="./assets/button_close.png"
                                onClick={(e) => {
                                    let { currentuser, menuOpen } = state;
                                    menuOpen = false;
                                    setState({ ...state, menuOpen });
                                }}
                                style={{
                                    marginTop: 25,
                                    width: "10%"
                                }}
                            />

                            <IonLabel
                                style={{
                                    marginTop: 30,
                                    color: "#9A9A9A",
                                    fontSize: 12,
                                    display: "block"
                                }}
                            >User ID</IonLabel>
                            <IonLabel
                                style={{
                                    color: "#9A9A9A",
                                    fontSize: 16,
                                    display: "block"
                                }}
                            >{state.currentuser.username}</IonLabel>
                            <Link style={{
                                textDecoration: "none"
                            }}
                                to="/orderhistory">
                                <IonLabel
                                    style={link_styles}
                                >Order History</IonLabel>
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none"
                                }}
                                to="/howitworks">
                                <IonLabel
                                    style={link_styles}
                                >How it works</IonLabel>
                            </Link>


                            <hr
                                style={{
                                    background: "#DDDDDD",
                                    marginLeft: -16,
                                    marginTop: 28
                                }}
                            />
                        
                        </IonCard>
                    ) : ''
                }
            </IonCard>
        )

};

export default Menu;
